// export const solutionsOptionsAPI = "http://15.206.95.198:8000/v1/solution-list/?format=json";

// export const dataTableAPI = "http://15.206.95.198:8000/v1/data/?format=json&page=";
export const SERVER_URL = process.env.REACT_APP_API_ENDPOINT;
export const eventsApi = SERVER_URL + "api/events/";
export const eventsApiDecluster = SERVER_URL + "api/events/decluster";
export const diseaseCategoryApi = SERVER_URL + "api/diseases/";
export const stateCategoryApi = SERVER_URL + "api/states/";
export const districtCategoryApi = SERVER_URL + "api/districts/";
export const loginApi = SERVER_URL + "api/auth/login";
export const sourceCategoryApi = SERVER_URL + "api/sources/";
export const langaugeCategoryApi = SERVER_URL + "api/languages/";
export const sortCategoryApi = SERVER_URL + "api/sort/";
export const dashboardApi = SERVER_URL + "api/dashboard";
export const reportDownloadApi = SERVER_URL + "api/mongodata/";
export const eventReadApi = SERVER_URL + "api/events/read";
export const onboardingCompletedApi = SERVER_URL + "api/onboarding_tutorial";
export const sourceRejection = SERVER_URL + "api/source_rejection/"
export const hideEventApi = SERVER_URL + "api/hide_event/"
