/* eslint-disable no-unneeded-ternary */
/* eslint-disable brace-style */
/* eslint-disable prefer-const */
/* eslint-disable keyword-spacing */
/* eslint-disable dot-notation */
/* eslint-disable space-before-function-paren */
/* eslint-disable no-extra-boolean-cast */
/* eslint-disable quote-props */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable camelcase */
import * as React from 'react';
import { getDiseaseCategories, getEvents, getEventsCount, getStateCategories, postCustomPublishEvent, postLogin, postPublishEvent, postShortlistEvent, getCurrentTime, getYesterDayDateTime, postRepublishEvent, getRelatedEventsData, getEventSummaryData, getEventSubTypes, postRejectEvent, postRemoveEvent, getSourceCategories, getDistrictCategories, getLanguageCategories, getSortCategories, postSendEventForApproval, postUnrejectEvent, getGeneratePDF, getGuestToken, getJobId, getReportDownload, eventChecked, onboardingCompleted, hideEvent } from "../actions/global"
import Box from '@material-ui/core/Box';
import Cards from "../components/Cards/Cards"
import CssBaseline from '@material-ui/core/CssBaseline';
import Divider from '@material-ui/core/Divider';
import Drawer from '@material-ui/core/Drawer';
import Grid from '@material-ui/core/Grid';
import distLookup from "../components/data/dist_lookup.json"
import { useDispatch } from 'react-redux'
import RepublishDialog from '../components/RepublishDialog';
import { dashboardPerformances, subActionTypeMap, dashboardSubTypes } from '../constants/app-constants';
import { capitalizeFirstLetter, convertStrObjToNumberObj, getUserIdFromToken, getUserStateFromToken } from '../actions/methods';
import SummaryDialog from '../components/SummaryDialog/SummaryDialog';
import ClusterDialog from '../components/ClusterDialog';
import CustomPublishDialog from '../components/CustomPublishDialog/CustomPublishDialog';
import bgImage from '../resources/pattern.png';
import FilterSection from '../components/FilterSection/FilterSection';
import CustomHelpButton from '../components/Shared/CustomHelpButton/CustomHelpButton';
import CustomHeaderButton from '../components/Shared/CustomHeaderTab/CustomHeaderTab';
import CustomSnackBar from '../components/Shared/CustomSnackBar/CustomSnackBar';
import GeneratePDFDialog from '../components/GeneratePDFDialog/GeneratePDFDialog';
import SolutionDashboard from '../components/SolutionDashboard/SolutionDashboard';
import DownloadReportDialog from '../components/DownloadReportDialog/DownloadReportDialog';
import { onboardingSelectorIds } from '../constants/Onboarding-Constants/idSelector';
import JoyRideSolutionPage from '../components/Onboarding/components/SolutionPage';
import PDFCompletedDialog from '../components/GeneratePDFDialog/PDFCompletedDialog';
import useNetworkStatus from '../hooks/useNetworkStatus';
import NetworkErrorPage from '../components/NetworkErrorPage/NetworkErrorPage';
import SourceRejection from '../components/SourceRejectionDialogs/SourceRejection';

export default function DashboardComponent() {
  const dispatch = useDispatch();
  const [token, setToken] = React.useState("")
  const [authenticatedWebsite, setAuthenticatedWebsite] = React.useState(false)
  const [content, setContent] = React.useState("extractedEvents")
  const [diseaseCategories, setDiseaseCategories] = React.useState([]);
  const [selectedDiseaseCategory, setSelectedDiseaseCategory] = React.useState([])
  const [selectedStateCategory, setSelectedStateCategory] = React.useState([])
  const [selectedLanguage, setSelectedLanguage] = React.useState([])
  const [extractedEventsState, setExtractedEventsState] = React.useState([])
  const [shortlistedEventsState, setShortlistedEventsState] = React.useState([])
  const [publishedEventsState, setPublishedEventsState] = React.useState([])
  const [importantNoEventArticles, setImportantNoEventArticles] = React.useState([])
  const [renderCards, setRenderCards] = React.useState(true)
  const [fromDateFilter, setFromDateFilter] = React.useState(getYesterDayDateTime())
  const [toDateFilter, setToDateFilter] = React.useState(getCurrentTime())
  const [eventCountLimit, setEventCountLimit] = React.useState(false)
  const itemsPerPage = 10;
  const [downloadReportTimer, setDownloadReportTimer] = React.useState(15000);
  const [page, setPage] = React.useState(1);
  const [noOfPages, setNoOfpages] = React.useState(1);
  const [pageChangeFlag, setPageChangeFlag] = React.useState(false);
  const [stateCategories, setStateCategories] = React.useState([]);
  const [snackBarOpen, setSnackBarOpen] = React.useState(false);
  const [shortlistEventIndexState, setShortlistEventIndexState] = React.useState(-1)
  const [rejectEventIndexState, setRejectEventIndexState] = React.useState(-1)
  const [removeEventIndexState, setRemoveEventIndexState] = React.useState(-1);
  const [publishSnackBarOpen, setPublishSnackBarOpen] = React.useState(false);
  const [publishEventIndexState, setPublishEventIndexState] = React.useState(-1)
  const [authenticationToken, setAuthenticationToken] = React.useState("")
  const [successfullShortlist, setSuccessfullShortlist] = React.useState(false)
  const [successfullPublish, setSuccessfullPublish] = React.useState(false)
  const [successfullReject, setSuccessfullReject] = React.useState(false);
  const [successfullCustomPublish, setSuccessfullCustomPublish] = React.useState(false)
  const [successfullRemove, setSuccessfullRemove] = React.useState(false);
  const [customPublishSnackBarOpen, setCustomPublishSnackBarOpen] = React.useState(false);
  const [rejectSnackBarOpen, setRejectSnackBarOpen] = React.useState(false);
  const [removeSnackBarOpen, setRemoveSnackBarOpen] = React.useState(false);
  const [countEvents, setCountEvents] = React.useState(0)
  const [republishDialogFlag, setRepublishDialogBoxState] = React.useState(false)
  const [selectedEventData, setSelectedEventData] = React.useState({})
  const [republishEventData, setRepublishEventData] = React.useState(null)
  const [downloadReportDialogFlag, setDownloadReportDialogFlag] = React.useState(false);
  const [downloadReportFlag, setDownloadReportFlag] = React.useState(false);
  const [downloadReportTimerFlag, setDownloadReportTimerFlag] = React.useState(false);
  const [downloadReportJobId, setDownloadReportJobId] = React.useState("");
  const [downloadReportFaliSnackbar, setDownloadReportFailSnackbar] = React.useState(false);
  const [ihipToken, setIhipToken] = React.useState("")
  const [stateUserFlag, setStateUserFlag] = React.useState(false)
  const [summaryDialogFlag, setSummaryDialogFlag] = React.useState(false)
  const [summaryData, setSummaryData] = React.useState({});
  const languages = ["English", "Hindi", "Telugu", "Tamil", "Kannada", "Marathi", "Bengali", "Gujarati", "Punjabi", "Malayalam", "Odia"]
  const incidentEnums = ['Case', 'Death']
  const languageAbbre = {
    English: "en",
    Hindi: "hi",
    Telugu: "te",
    Tamil: "ta",
    Kannada: "kn",
    Marathi: "mr",
    Bengali: "bn",
    Gujarati: "gu",
    Punjabi: "pa",
    Malayalam: "ml",
    Odia: "or"
  }
  const [customEventDate, setCustomEventDate] = React.useState()
  const [customEventDisease, setCustomEventDisease] = React.useState()
  const [customEventNumberOfCases, setCustomEventNumberOfCases] = React.useState()
  const [customEventState, setCustomEventState] = React.useState()
  const [customEventDistrict, setCustomEventDistrict] = React.useState()
  const [customEventURL, setCustomEventURL] = React.useState()
  const [customEventLanguage, setCustomEventLanguage] = React.useState()
  const [customEventIncident, setCustomEventIncident] = React.useState()
  const [customEventMessage, setCustomEventMessage] = React.useState()
  const [customStateDistrictList, setCustomStateDistrictList] = React.useState({});
  const [languageList, setLanguageList] = React.useState([]);
  const [clusterEventDialogFlag, setClusterEventDialogFlag] = React.useState(false);
  const [clusterEventList, setClusterEventList] = React.useState([])
  const [customPublishDialogFlag, setCustomPublishDialogFlag] = React.useState(false);
  const [aiTranslationFlag, setAiTranslationFlag] = React.useState(true);
  const [collapseFlag, setCollpaseFlag] = React.useState(true);
  const [eventSubTypes, setEventSubTypes] = React.useState([]);
  const [selectedEventSubType, setSelectedEventSubType] = React.useState({});
  const [sourceCategories, setSourceCategories] = React.useState([]);
  const [selectedSourceCategory, setSelectedSourceCategory] = React.useState([]);
  const [districtCategories, setDistrictCategories] = React.useState([]);
  const [selectedDistrictCategory, setSelectedDistrictCategory] = React.useState([]);
  const [selectedSortType, setSelectedSortType] = React.useState(null);
  const [sortTypeCategories, setSortTypeCategories] = React.useState([]);
  const [successfullSendApproval, setSuccessfullSendApproval] = React.useState(false);
  const [sendApprovalSnackBarOpen, setSendApprovalSnackBarOpen] = React.useState(false);
  const [successfullUnrejectEvent, setSuccessfullUnrejectEvent] = React.useState(false);
  const [unrejectEventSnackBarOpen, setUnrejectEventSnackBarOpen] = React.useState(false);
  const [buttonFreeze, setButtonFreeze] = React.useState(false);
  const [generatePDFDialogFlag, setGeneratePDFDialogFlag] = React.useState(false);
  const [selectedEventId, setSelectedEventId] = React.useState('');
  const [selectedEventForPrintPDF, setSelectedEventForPrintPDF] = React.useState({});
  const [printPDFSnackBarOpen, setPrintPDFSnackBarOpen] = React.useState(false);
  const [totalEventsCount, setTotalEventsCount] = React.useState(0);
  const [guestToken, setGuestToken] = React.useState("");
  const [superSetID, setSuperSetID] = React.useState("");
  const [superSetDomain, setSuperSetDomain] = React.useState("");
  const [isValidEventData, setIsValidEventData] = React.useState(true);
  const [filterDateEmptySnackbar, setFilterDateEmptySnackbar] = React.useState(false);
  const [selectedDashboardPerformance, setSelectedDashboardPerformance] = React.useState(dashboardPerformances[0]);
  const [callEventCallForFirstTimeFlag, setCallEventCallForFirstTimeFlag] = React.useState(false)
  const [showLoaderForTotalCount, setShowLoaderForTotalCount] = React.useState(false);
  const [userId, setUserId] = React.useState('');
  const [isOnboardingCompleted, setOnboardingCompleted] = React.useState(false);
  const [pdfDownloadCompletedFlag, setPdfDownloadCompletedFlag] = React.useState(false);
  const [generatedPdfInfoId, setGeneratedPdfInfoId] = React.useState('');
  const [summaryDataFetchingFlag, setSummaryDataFetchingFlag] = React.useState(false)
  const currentTab = React.useRef();
  currentTab.current = content;
  const isOnline = useNetworkStatus();
  const [mounted, setMounted] = React.useState(false);
  const [showRejectionDialog, setShowRejectionDialog] = React.useState({
    showRejectedSourceList: false,
    showPostRejectionClick: false,
    url: "",
    eventId: ""
  })

  const handleDiseaseCategoryChange = (event) => {
    setSelectedDiseaseCategory(event.target.value)
  };
  const handleLanguageChange = (event) => {
    setSelectedLanguage(event.target.value)
  }
  const handleStateCategoryChange = (event) => {
    setSelectedStateCategory(event.target.value)
  }
  const handleDistrictCategoryChange = (event) => {
    setSelectedDistrictCategory(event.target.value)
  }
  const handleFromDateChange = (date) => {
    setFromDateFilter(date)
  }
  const handleToDateChange = (date) => {
    setToDateFilter(date)
  }
  const handleSourceChange = (event) => {
    setSelectedSourceCategory(event.target.value);
  }

  function getFiltersData(authenticationTokenParam) {
    dispatch(getDiseaseCategories(authenticationTokenParam)).then((res) => {
      var diseaseCategoryData = res.diseases !== undefined ? res.diseases : []
      setDiseaseCategories([].concat(diseaseCategoryData))
      const selectedDiseases = diseaseCategoryData.flatMap(disease => disease.items).map(disease => disease.value).filter(dis => dis !== "COVID-19");
      setSelectedDiseaseCategory([].concat(selectedDiseases))
      setCallEventCallForFirstTimeFlag(true)
    })
    dispatch(getStateCategories(authenticationTokenParam)).then((res) => {
      var stateCategoryData = res.states !== undefined ? res.states : []
      if (!!!getUserStateFromToken(authenticationToken)) {
        stateCategoryData.unshift({
          "name": "No State",
          "value": ""
        })
      }
      setStateCategories([].concat(stateCategoryData))
      const selectedStates = stateCategoryData.map(st => st.value);
      setSelectedStateCategory([].concat(selectedStates))
    })
    dispatch(getSourceCategories(authenticationTokenParam)).then((res) => {
      var sourceCategoryData = res.sources !== undefined ? res.sources : []
      setSourceCategories([].concat(sourceCategoryData))
      const sources = sourceCategoryData.flatMap(item => item.items.map(subItem => subItem.value));
      setSelectedSourceCategory([].concat(sources))
    })
    dispatch(getLanguageCategories(authenticationTokenParam)).then((res) => {
      var languageCategoryData = res.languages !== undefined ? res.languages : []
      setLanguageList([].concat(languageCategoryData))
      const selectedLanguages = languageCategoryData.map(lang => lang.value);
      setSelectedLanguage([].concat(selectedLanguages))
    })
  }

  function getSortFilterList(eventTypeParam, authenticationTokenParam) {
    let tempEventType = null
    if (eventTypeParam === "extractedEvents") {
      tempEventType = "new"
    } else if (eventTypeParam === "shortlistedEvents") {
      tempEventType = "shortlisted"
    } else if (eventTypeParam === "publishedEvents") {
      tempEventType = "published"
    }
    dispatch(getSortCategories(tempEventType, authenticationTokenParam)).then((res) => {
      var sortCategoryData = res.sort !== undefined ? res.sort : []
      setSortTypeCategories([].concat(sortCategoryData))
      setSelectedSortType(sortCategoryData[0].value)
    })
  }

  function getDistrictFilterData(stateCategoryParam, authenticationTokenParam) {
    dispatch(getDistrictCategories(stateCategoryParam, authenticationTokenParam)).then((res) => {
      var districtCategoryData = res.districts !== undefined ? res.districts : []
      if (!!districtCategoryData) {
        districtCategoryData.unshift({
          "name": "Other",
          "items": [
            {
              "name": "No District",
              "value": ""
            }
          ]
        })
      }
      let dists = districtCategoryData.flatMap(state => state.items).map(dist => dist.value)
      setSelectedDistrictCategory([].concat(dists))
      setDistrictCategories([].concat(districtCategoryData))
    })
  }

  function getEventsList(fromDateParam, toDateParam, tempEventType, stateCategoryParam, districtCategoryParam, diseaseCategoryParam, languageParam, pageParam, itemsPerPageParam, selectedEventSubTypeVal, sourceCategoryParam, sortCategoryParam, authenticationTokenParam, setTempEvents) {
    dispatch(getEvents(fromDateParam, toDateParam, tempEventType, stateCategoryParam, districtCategoryParam, diseaseCategoryParam, languageParam, pageParam, itemsPerPageParam, selectedEventSubTypeVal, sourceCategoryParam, sortCategoryParam, authenticationTokenParam)).then((res) => {
      if (currentTab.current !== content) {
        return;
      }
      let eventData = res.events !== undefined ? res.events : []
      setTempEvents([].concat(eventData));
    })
  }

  function callEvents(fromDateParam, toDateParam, eventTypeParam, stateCategoryParam, districtCategoryParam, diseaseCategoryParam, languageParam, pageParam, itemsPerPageParam, selectedEventSubTypeVal, sourceCategoryParam, sortCategoryParam, authenticationTokenParam, contentChangeFlag, filterChangeFlag, callEventSubTypeApi = true) {
    if (contentChangeFlag) {
      setShowLoaderForTotalCount(true)
    }
    var tempEventType = null
    var tempSetEvents = null
    let tempEventSubType;
    if (eventTypeParam === "extractedEvents") {
      tempEventType = "new"
      tempSetEvents = setExtractedEventsState
      tempEventSubType = dashboardSubTypes.extractedEvents[0]
      if(callEventSubTypeApi && contentChangeFlag) {
        setEventSubTypes(dashboardSubTypes.extractedEvents);
      }
    } else if (eventTypeParam === "shortlistedEvents") {
      tempEventType = "shortlisted"
      tempSetEvents = setShortlistedEventsState
      tempEventSubType = dashboardSubTypes.shortlistedEvents[0]
      if(callEventSubTypeApi && contentChangeFlag) {
        setEventSubTypes(dashboardSubTypes.shortlistedEvents);
      }
    } else if (eventTypeParam === "publishedEvents") {
      tempEventType = "published"
      tempSetEvents = setPublishedEventsState
      tempEventSubType = dashboardSubTypes.publishedEvents[0]
      if(callEventSubTypeApi && contentChangeFlag) {
        setEventSubTypes(dashboardSubTypes.publishedEvents);
      }
    }

    const stateCategory = selectedStateCategory.length === stateCategories.length ? [] : stateCategoryParam;

    dispatch(getEventsCount(fromDateParam, toDateParam, tempEventType, stateCategory, districtCategoryParam, diseaseCategoryParam, languageParam, tempEventSubType, sourceCategoryParam, authenticationTokenParam)).then((res) => {
      if (currentTab.current !== content) {
        return;
      }
      var count = res.count
      console.log("count", count)
      if (count !== undefined) {
        if (count < 10000) {
          if (contentChangeFlag || filterChangeFlag) {
            setTotalEventsCount(count)
            setDownloadReportTimer(count < 200 ? 3000 : count < 1000 ? count * 5 : count * 3)
            setShowLoaderForTotalCount(false)
          }
          setEventCountLimit(false);
          if(callEventSubTypeApi) {
            dispatch(getEventSubTypes(fromDateParam, toDateParam, tempEventType, stateCategory, districtCategoryParam, languageParam, diseaseCategoryParam, sourceCategoryParam, authenticationTokenParam)).then((res) => {
              if (currentTab.current !== content) {
                return;
              }
              var eventSubTypesArr = res.types !== undefined ? res.types : []
              if (eventSubTypes === [] || eventSubTypesArr[0]?.type !== eventSubTypes[0]?.type) {
                setEventSubTypes(eventSubTypesArr)
                setSelectedEventSubType(eventSubTypesArr[0])
                updateEventCountAndNoOfPages(eventSubTypesArr[0])
              }
              else {
                const eventSubTypeCode = (selectedEventSubTypeVal.code !== undefined) ? selectedEventSubTypeVal.code : "0"
                const filteredEventSubtype = eventSubTypesArr.filter(subtype => subtype.code === eventSubTypeCode)
                setEventSubTypes(eventSubTypesArr)
                setSelectedEventSubType(filteredEventSubtype[0]);
                updateEventCountAndNoOfPages(filteredEventSubtype[0]);
              }
            })
          } else {
            updateEventCountAndNoOfPages(selectedEventSubTypeVal)
          }
          if (sortCategoryParam !== "") {
            getEventsList(fromDateParam, toDateParam, tempEventType, stateCategory, districtCategoryParam, diseaseCategoryParam, languageParam, pageParam, itemsPerPageParam, selectedEventSubTypeVal, sourceCategoryParam, sortCategoryParam, authenticationTokenParam, tempSetEvents)
          }
        } else if (count >= 10000) {
          setEventCountLimit(true)
        }
      }
    })
  }

  function updateEventCountAndNoOfPages(eventSubType) {
    const eventCount = eventSubType.count;
    setCountEvents(eventCount)
    setNoOfpages(Math.ceil(eventCount / itemsPerPage));
  }

  function getDashboardData() {
    dispatch(getGuestToken(selectedDashboardPerformance["dashboardType"], authenticationToken)).then((res) => {
      if (res?.success === true) {
        setGuestToken(res?.data.token);
        setSuperSetID(res?.data.id);
        setSuperSetDomain(res?.data.url);
      }
    });
  }

  function getUserStateDistrict() {
    const state = getUserStateFromToken(authenticationToken);
    if (state != null) {
      var obj = {};
      obj[state] = distLookup[state]
      setStateUserFlag(true)
      setCustomStateDistrictList(obj);
    } else {
      setStateUserFlag(false)
      setCustomStateDistrictList(distLookup);
    }
  }

  function getUserId() {
    const userId = getUserIdFromToken(authenticationToken)
    setUserId(userId)
  }

  React.useEffect(() => {
    if (selectedStateCategory.length > 0) {
      getDistrictFilterData(selectedStateCategory, authenticationToken)
    } else {
      setSelectedDistrictCategory([])
    }
  }, [selectedStateCategory]);

  React.useEffect(() => {
    console.log("hello");
    const currentUrl = new URL(window.location.href);
    const token = currentUrl.searchParams.get('token');
    console.log(token);
    setToken(JSON.stringify(token))
    // window.parent.postMessage("SEND_AUTH_TO_IDSP", '*');
    // window.addEventListener('message', function (e) {
    //   console.log("IHIP MESSAGE >>>>>>>>>", e)
    //   if (typeof e.data === "string" && e.data !== "" && e.data !== "SEND_AUTH_TO_IDSP") {
    //     setToken(e.data);
    //   }
    // });
  }, [])

  React.useEffect(() => {
    if (token !== "" && token !== undefined && token !== null) {
      postLogin(token).then((res) => {
        if (res?.success === true) {
          setAuthenticatedWebsite(true)
          setIhipToken(res?.data?.ihip_token)
          setAuthenticationToken(res?.data?.token)
          setOnboardingCompleted(res?.data?.onboarding_completed === "completed" ? true : false)
        }
      })
    }
  }, [token])

  function checkIsAllFilterDataLoaded () {
    if(selectedDiseaseCategory.length > 0 && selectedStateCategory.length > 0 && selectedDistrictCategory.length > 0 && selectedLanguage.length > 0 && selectedSourceCategory.length > 0) {
      console.log(selectedDistrictCategory)
      setCallEventCallForFirstTimeFlag(true)
    }
  }

  React.useEffect(() => {
    if(!setCallEventCallForFirstTimeFlag && checkIsAllFilterDataLoaded()) {
      setCallEventCallForFirstTimeFlag(true)
    }
  }, [selectedDiseaseCategory, selectedStateCategory, selectedDistrictCategory, selectedLanguage, selectedSourceCategory])

  React.useEffect(() => {
    if (authenticationToken !== "") {
      getUserStateDistrict();
      getUserId();
      if (content !== "customPublish" && content !== "dashboard") {
        getFiltersData(authenticationToken)
      }
    }
  }, [authenticationToken]);

  React.useEffect(() => {
    if (authenticationToken !== "" && callEventCallForFirstTimeFlag && content !== "") {
      getUserStateDistrict();
      if (content !== "customPublish" && content !== "dashboard") {
        callEvents(fromDateFilter, toDateFilter, content, selectedStateCategory, selectedDistrictCategory, selectedDiseaseCategory, selectedLanguage, page, itemsPerPage, selectedEventSubType, selectedSourceCategory, null, authenticationToken, true)
        getSortFilterList(content, authenticationToken)
        setRenderCards(!renderCards)
      }
    }
  }, [content, callEventCallForFirstTimeFlag])

  React.useEffect(() => {
    if (authenticationToken !== "") {
      getUserStateDistrict();
      if (content !== "customPublish" && content !== "dashboard") {
        callEvents(fromDateFilter, toDateFilter, content, selectedStateCategory, selectedDistrictCategory, selectedDiseaseCategory, selectedLanguage, page, itemsPerPage, selectedEventSubType, selectedSourceCategory, selectedSortType, authenticationToken, false, false, false)
        setRenderCards(!renderCards)
      }
    }
  }, [pageChangeFlag, itemsPerPage])

  React.useEffect(() => {
    if (authenticationToken !== "") {
      if (rejectEventIndexState !== -1) {
        console.log(rejectEventIndexState);
        rejectEvent(rejectEventIndexState[0], rejectEventIndexState[1])
      }
    }
  }, [rejectEventIndexState, authenticationToken])

  React.useEffect(() => {
    if(authenticationToken !== "") {
      if(removeEventIndexState !== -1) {
        console.log(removeEventIndexState);
        removeEvent(removeEventIndexState)
      }
    }
  }, [removeEventIndexState, authenticationToken])

  React.useEffect(() => {
    if (authenticationToken !== "") {
      if (shortlistEventIndexState !== -1) {
        shortlistEvent(shortlistEventIndexState[0], shortlistEventIndexState[1])
      }
    }
  }, [shortlistEventIndexState, authenticationToken])

  React.useEffect(() => {
    if (authenticationToken !== "") {
      if (publishEventIndexState !== -1) {
        publishEvent(publishEventIndexState[0], publishEventIndexState[1])
      }
    }
  }, [publishEventIndexState, authenticationToken])

  React.useEffect(() => {
    console.log("republish effect", republishEventData)
    if (authenticationToken !== "") {
      if (republishEventData) {
        republishEvent(republishEventData)
        setRepublishEventData(null)
      }
    }
  }, [republishEventData, authenticationToken])

  React.useEffect(() => {
    if (selectedSortType && countEvents > 0) {
      let tempEventType = null
      var tempSetEvents = null
      if (content === "extractedEvents") {
        tempEventType = "new"
        tempSetEvents = setExtractedEventsState
      } else if (content === "shortlistedEvents") {
        tempEventType = "shortlisted"
        tempSetEvents = setShortlistedEventsState
      } else if (content === "publishedEvents") {
        tempEventType = "published"
        tempSetEvents = setPublishedEventsState
      }
      getEventsList(fromDateFilter, toDateFilter, tempEventType, selectedStateCategory, selectedDistrictCategory, selectedDiseaseCategory, selectedLanguage, page, itemsPerPage, selectedEventSubType, selectedSourceCategory, selectedSortType, authenticationToken, tempSetEvents)
    }
  }, [selectedSortType]);

  React.useEffect(() => {
    if (content === "dashboard" && authenticationToken !== "") {
      getDashboardData();
    }
  }, [content, authenticationToken, selectedDashboardPerformance])

  const clickExtractedEvents = () => {
    if(content !== "extractedEvents") {
      setContent("extractedEvents")
      setSelectedEventSubType({});
      setPage(1)
      setRenderCards(!renderCards)
    }
  }

  const openDashboard = () => {
    if(content !== "dashboard") {
      setContent("dashboard");
      setSelectedEventSubType({});
      setEventSubTypes([]);
      console.log("Dashbaord opened")
    }
  }

  const handleSnakBarClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setSnackBarOpen(false);
  };
  const handlePublishSnackBarClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setPublishSnackBarOpen(false);
  };
  const handleCustomPublishSnackBarClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setCustomPublishSnackBarOpen(false);
  };
  const handleSnakBarCloseEventLimit = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setEventCountLimit(false);
  };
  const handleRejectSnackBarClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setRejectSnackBarOpen(false);
  };
  const handleRemoveSnackBarClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setRemoveSnackBarOpen(false);
  }
  const handleValidationSnakBarClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setIsValidEventData(true);
  }
  const handleFilterDateEmptySnakBarClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setFilterDateEmptySnackbar(false);
  }
  const handleSendApprovalSnackBarClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setSendApprovalSnackBarOpen(false);
  };
  const handleUnrejectEventSnackBarClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setUnrejectEventSnackBarOpen(false);
  };
  const handlePrintPDFSnackBarClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setPrintPDFSnackBarOpen(false);
  };
  const handleDownloadReportSnackBarClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setDownloadReportFailSnackbar(false);
  };

  const clickShortlistedEvents = () => {
    if(content !== "shortlistedEvents") {
      setContent("shortlistedEvents")
      setPage(1);
      setSelectedEventSubType({});
      setRenderCards(!renderCards)
    }
  }
  const clickPublished = () => {
    if(content !== "publishedEvents") {
      setContent("publishedEvents")
      setPage(1);
      setSelectedEventSubType({});
      setRenderCards(!renderCards)
    }
  }
  const handlePageChange = (value) => {
    setExtractedEventsState([])
    setShortlistedEventsState([])
    setPublishedEventsState([])
    setPageChangeFlag(!pageChangeFlag)
    setPage(value);
  };
  function shortlistEvent(eventData, shortlist_type) {
    if(!isValidEventData) {
      return;
    }
    var eventJSON = {
      action_type: shortlist_type,
      event_id: eventData.id,
      disease: eventData.disease,
      disease_macro_category: eventData.disease_macro_category,
      state: eventData.state,
      district: capitalizeFirstLetter(eventData.district),
      sub_district: eventData.sub_district,
      date: eventData.extracted_date,
      number: convertStrObjToNumberObj(eventData.number),
      url: eventData.url,
      cluster_id: eventData.cluster_id
    }
    setButtonFreeze(true)
    dispatch(postShortlistEvent(eventJSON, authenticationToken)).then((res) => {
      if (clusterEventDialogFlag) {
        setClusterEventDialogFlag(false)
      }
      console.log(" shortlist dispatch", res)
      if (res === true) {
        setSuccessfullShortlist(true)
        setSnackBarOpen(true);
        console.log("true")
        setTimeout(function () { callEvents(fromDateFilter, toDateFilter, content, selectedStateCategory, selectedDistrictCategory, selectedDiseaseCategory, selectedLanguage, page, itemsPerPage, selectedEventSubType, selectedSourceCategory, selectedSortType, authenticationToken, true) }, 1000);
      } else {
        setSuccessfullShortlist(false)
        setSnackBarOpen(true);
      }
      setButtonFreeze(false)
    })
    setRenderCards(!renderCards)
  }
  function publishEvent(eventData, publish_type) {
    if(!isValidEventData) {
      return;
    }
    var eventJSON = {
      action_type: publish_type,
      event_id: eventData.id,
      disease: eventData.disease,
      disease_macro_category: eventData.disease_macro_category,
      state: eventData.state,
      district: capitalizeFirstLetter(eventData.district),
      date: eventData.extracted_date,
      number: convertStrObjToNumberObj(eventData.number),
      url: eventData.url,
      ihip_token: ihipToken,
      cluster_id: eventData.cluster_id
    }
    if(eventData["local_body_code"] && eventData["sub_district"]) {
      eventJSON["sub_district"] = eventData.sub_district
      eventJSON["local_body_code"] = eventData.local_body_code
    }
    setButtonFreeze(true);
    dispatch(postPublishEvent(eventJSON, authenticationToken)).then((res) => {
      console.log(" publsih dispatch", res)
      if (clusterEventDialogFlag) {
        setClusterEventDialogFlag(false)
      }
      if (res === true) {
        console.log("true")
        setSuccessfullPublish(true)
        setPublishSnackBarOpen(true)
        setTimeout(function () { callEvents(fromDateFilter, toDateFilter, content, selectedStateCategory, selectedDistrictCategory, selectedDiseaseCategory, selectedLanguage, page, itemsPerPage, selectedEventSubType, selectedSourceCategory, selectedSortType, authenticationToken, true) }, 1000);
      } else {
        setPublishEventIndexState(-1)
        setSuccessfullPublish(false)
        setPublishSnackBarOpen(true)
      }
      setButtonFreeze(false);
    })
    setRenderCards(!renderCards)
  }
  function customPublishEvent() {
    console.log("csutom publish clicked")
    var eventJSON = {
      disease: customEventDisease,
      state: customEventState,
      district: customEventDistrict,
      sub_district: "",
      date: customEventDate,
      message: customEventMessage,
      number: customEventNumberOfCases || 0,
      url: customEventURL,
      language: languageAbbre[customEventLanguage],
      incident: customEventIncident.toLowerCase(),
      ihip_token: ihipToken,
    }
    setButtonFreeze(true);
    dispatch(postCustomPublishEvent(eventJSON, authenticationToken)).then((res) => {
      setCustomPublishDialogFlag(false)
      console.log(" custom event dispatch", res)
      if (res === true) {
        setSuccessfullCustomPublish(true)
        setCustomPublishSnackBarOpen(true)
        setCustomEventDisease("")
        setCustomEventState("")
        setCustomEventDistrict("")
        setCustomEventDate("")
        setCustomEventMessage("")
        setCustomEventNumberOfCases("")
        setCustomEventURL("")
        setCustomEventLanguage("")
        setCustomEventIncident("")
        setContent("publishedEvents")
      } else {
        setSuccessfullCustomPublish(false)
        setCustomPublishSnackBarOpen(true)
      }
      setButtonFreeze(false);
    })
  }
  function republishEvent(eventData) {
    setRepublishDialogBoxState(false)
    var eventJSON = {
      event_id: eventData.id,
      disease: eventData.disease,
      disease_macro_category: eventData.disease_macro_category,
      state: eventData.state,
      district: capitalizeFirstLetter(eventData.district),
      date: eventData.extracted_date,
      number: convertStrObjToNumberObj(eventData.number),
      message: eventData.message,
      url: eventData.url,
      ihip_token: ihipToken,
      cluster_id: eventData.cluster_id
    }
    if(eventData["local_body_code"] && eventData["sub_district"]) {
      eventJSON["sub_district"] = eventData.sub_district
      eventJSON["local_body_code"] = eventData.local_body_code
    }
    setButtonFreeze(true);
    dispatch(postRepublishEvent(eventJSON, authenticationToken)).then((res) => {
      console.log(" publsih dispatch", res)
      if (res === true) {
        console.log("true")
        setSuccessfullPublish(true)
        setPublishSnackBarOpen(true)
        setTimeout(function () { callEvents(fromDateFilter, toDateFilter, content, selectedStateCategory, selectedDistrictCategory, selectedDiseaseCategory, selectedLanguage, page, itemsPerPage, selectedEventSubType, selectedSourceCategory, selectedSortType, authenticationToken, true) }, 1000);
      } else {
        setPublishEventIndexState(-1)
        setSuccessfullPublish(false)
        setPublishSnackBarOpen(true)
      }
      setButtonFreeze(false);
    })
    setRenderCards(!renderCards)
  }
  function rejectEvent(eventData, reject_type) {
    let eventJSON = {
      event_id: eventData.id,
      cluster_id: eventData.cluster_id
    }
    setButtonFreeze(true);
    if (content === "extractedEvents") {
      eventJSON["event_type"] = "new"
    } else if (content === "shortlistedEvents") {
      eventJSON["event_type"] = "shortlisted"
    } else if (content === "publishedEvents") {
      eventJSON["event_type"] = "published"
    }

    if (subActionTypeMap.rejectFakeSource === reject_type) {
      eventJSON["reject_type"] = "Fake News"
    }
    else if (subActionTypeMap.rejectOldNews === reject_type) {
      eventJSON["reject_type"] = "Old News"
    }
    else if (subActionTypeMap.rejectIrrelevant === reject_type) {
      eventJSON["reject_type"] = "Irrelevant News"
    }
    else if (subActionTypeMap.rejectPublished === reject_type) {
      eventJSON["reject_type"] = "Already Published"
    }
    else if (subActionTypeMap.rejectInternationalEvent === reject_type) {
      eventJSON["reject_type"] = "International Event"
    }
    dispatch(postRejectEvent(eventJSON, authenticationToken)).then((res) => {
      console.log(" reject dispatch", res)
      if (clusterEventDialogFlag) {
        setClusterEventDialogFlag(false)
      }
      if (res === true) {
        console.log("true")
        setRejectSnackBarOpen(true)
        setSuccessfullReject(true)
        setTimeout(function () { callEvents(fromDateFilter, toDateFilter, content, selectedStateCategory, selectedDistrictCategory, selectedDiseaseCategory, selectedLanguage, page, itemsPerPage, selectedEventSubType, selectedSourceCategory, selectedSortType, authenticationToken, true) }, 1000);
      } else {
        setRejectEventIndexState(-1)
        setSuccessfullReject(false)
        setRejectSnackBarOpen(true)
      }
      setButtonFreeze(false);
    })
    setRenderCards(!renderCards)
  }

  function removeEvent(eventData) {
    let eventJSON = {
      event_id: eventData.id,
      cluster_id: eventData.cluster_id
    }
    setButtonFreeze(true);
    dispatch(postRemoveEvent(eventJSON, authenticationToken)).then((res) => {
      console.log(" remove dispatch", res)
      if (clusterEventDialogFlag) {
        setClusterEventDialogFlag(false)
      }
      if (res === true) {
        setRemoveSnackBarOpen(true)
        setSuccessfullRemove(true)
        setTimeout(function () { callEvents(fromDateFilter, toDateFilter, content, selectedStateCategory, selectedDistrictCategory, selectedDiseaseCategory, selectedLanguage, page, itemsPerPage, selectedEventSubType, selectedSourceCategory, selectedSortType, authenticationToken, true) }, 1000);
      } else {
        setRemoveEventIndexState(-1)
        setSuccessfullRemove(false)
        setRemoveSnackBarOpen(true)
      }
      setButtonFreeze(false);
    })
    setRenderCards(!renderCards)
  }

  function getClusterEvents(cluster_id, event_id) {
    let event_type = ""
    if (content === "extractedEvents") {
      event_type = "new"
    } else if (content === "shortlistedEvents") {
      event_type = "shortlisted"
    } else if (content === "publishedEvents") {
      event_type = "published"
    }
    dispatch(getRelatedEventsData(fromDateFilter, toDateFilter, event_type, selectedStateCategory, selectedDiseaseCategory, selectedLanguage, selectedSourceCategory, selectedEventSubType, authenticationToken, cluster_id, event_id)).then((res) => {
      setClusterEventList(res?.clustered_events)
      setClusterEventDialogFlag(true);
    });
  }
  function openSummaryDialog(flag, id) {
      setSummaryDataFetchingFlag(true)
      dispatch(getEventSummaryData(id, authenticationToken)).then((res) => {
        setSummaryData({ "id": id, ...res?.summary })
        setSummaryDataFetchingFlag(false)
        setSummaryDialogFlag(flag)
      });
  }
  // function capitalizeFirstLetter(string) {
  //   return string.charAt(0).toUpperCase() + string.slice(1).toLowerCase();
  // }
  function fetchEvents() {
    if(fromDateFilter === null || toDateFilter === null) {
      setFilterDateEmptySnackbar(true);
      return;
    }
    setFilterDateEmptySnackbar(false);
    document.querySelector('body').click();
    setExtractedEventsState([]);
    setShortlistedEventsState([]);
    setPublishedEventsState([]);
    callEvents(fromDateFilter, toDateFilter, content, selectedStateCategory, selectedDistrictCategory, selectedDiseaseCategory, selectedLanguage, 1, itemsPerPage, selectedEventSubType, selectedSourceCategory, selectedSortType, authenticationToken, true, true);
    setPage(1);
    setRenderCards(!renderCards)
  }
  function openCustomPublishDialog() {
    setCustomPublishDialogFlag(true);
  }
  function getEventsBySubType(subType) {
    console.log(subType);
    setSelectedEventSubType(subType);
    setPage(1);
    callEvents(fromDateFilter, toDateFilter, content, selectedStateCategory, selectedDistrictCategory, selectedDiseaseCategory, selectedLanguage, 1, itemsPerPage, subType, selectedSourceCategory, selectedSortType, authenticationToken, false, false, false);
  }
  function downloadReportDialog () {
    setDownloadReportDialogFlag(true);
    setDownloadReportTimerFlag(false);
    setDownloadReportFailSnackbar(false);
    setTimeout(() => {
      setDownloadReportTimerFlag(true)
      setDownloadReportFlag(true);
    }, downloadReportTimer);
    setDownloadReportFlag(false)
    const eventType = content === "extractedEvents" ? "new" : content === "shortlistedEvents" ? "shortlisted" : content === "publishedEvents" ? "published" : "";
    dispatch(getJobId(eventType, fromDateFilter, toDateFilter, selectedDiseaseCategory, selectedStateCategory, selectedDistrictCategory, selectedLanguage, selectedSourceCategory, selectedEventSubType, authenticationToken)).then((res) => {
      if (res) {
        const jobIdRes = res.data.job_id !== undefined ? res.data.job_id : "";
        setDownloadReportJobId(jobIdRes);
      }
    });
  }

  function eventRead(eventData) {
    dispatch(eventChecked(eventData, authenticationToken));
  }

  function downloadReport () {
    dispatch(getReportDownload(downloadReportJobId, authenticationToken)).then((res) => {
      if (res) {
        if (res.data.status === "COMPLETE") {
          window.location.href = res.data.url;
          setDownloadReportDialogFlag(false);
        } else if(res.data.status === "FAILED") {
          setDownloadReportDialogFlag(false);
          setDownloadReportFailSnackbar(true);
        }
      }
    });
  }
  function sendClusterForApproval(flag, eventData) {
    let eventJSON = {
      event_id: eventData.id,
      cluster_id: eventData.cluster_id
    }
    setButtonFreeze(true);
    if (content === "extractedEvents") {
      eventJSON["event_type"] = "new"
    } else if (content === "shortlistedEvents") {
      eventJSON["event_type"] = "shortlisted"
    } else if (content === "publishedEvents") {
      eventJSON["event_type"] = "published"
    }
    dispatch(postSendEventForApproval(eventJSON, authenticationToken)).then((res) => {
      console.log(" send for approval dispatch", res)
      if (clusterEventDialogFlag) {
        setClusterEventDialogFlag(false)
      }
      if (res === true) {
        console.log("true")
        setSendApprovalSnackBarOpen(true)
        setSuccessfullSendApproval(true)
        setTimeout(function () { callEvents(fromDateFilter, toDateFilter, content, selectedStateCategory, selectedDistrictCategory, selectedDiseaseCategory, selectedLanguage, page, itemsPerPage, selectedEventSubType, selectedSourceCategory, selectedSortType, authenticationToken) }, 1000);
      } else {
        setShortlistEventIndexState(-1)
        setSendApprovalSnackBarOpen(true)
        setSuccessfullSendApproval(false)
      }
      setButtonFreeze(false);
    })
    setRenderCards(!renderCards)
  }

  function approveActionForCluster() {

  }

  function sendEmailForCluster() {

  }

  function openPrintPDFDialog(flag, data) {
    setSelectedEventId(data.id);
    setSelectedEventForPrintPDF(data);
    setGeneratePDFDialogFlag(true);
  }

  function printPublishPdf(info_id, published_date, reporting_date, location, url, language, title, description, setDownloadingFlag) {
    if (!(title.length > 0 && description.length > 0)) {
      setPrintPDFSnackBarOpen(true);
      return
    }
    let eventJSON = {
      info_id: info_id,
      publication_date: published_date,
      reporting_date: reporting_date,
      place_name: location,
      link: url,
      language: language,
      title: title,
      description: description
    }
    dispatch(getGeneratePDF(eventJSON, authenticationToken)).then((res) => {
      if (res.success) {
        const dateObj = new Date(published_date);
        const fileName = `${info_id}_${dateObj.getDate()}-${dateObj.getMonth() + 1}-${dateObj.getFullYear()}_${location.replace(', ', '_')}.pdf`
        setGeneratedPdfInfoId(fileName)
        window.location.href = res.data.url;
        setDownloadingFlag(false)
        setPdfDownloadCompletedFlag(true)
      }
      else {
        alert("Something went wrong");
      }
      setGeneratePDFDialogFlag(false);
    });
  }

  function unRejectCluster(flag, eventData) {
    let eventJSON = {
      event_id: eventData.id,
      cluster_id: eventData.cluster_id
    }
    setButtonFreeze(true);
    if (content === "extractedEvents") {
      eventJSON["event_type"] = "new"
    } else if (content === "shortlistedEvents") {
      eventJSON["event_type"] = "shortlisted"
    } else if (content === "publishedEvents") {
      eventJSON["event_type"] = "published"
    }
    dispatch(postUnrejectEvent(eventJSON, authenticationToken)).then((res) => {
      console.log(" send for approval dispatch", res)
      if (clusterEventDialogFlag) {
        setClusterEventDialogFlag(false)
      }
      if (res === true) {
        console.log("true")
        setUnrejectEventSnackBarOpen(true)
        setSuccessfullUnrejectEvent(true)
        setTimeout(function () { callEvents(fromDateFilter, toDateFilter, content, selectedStateCategory, selectedDistrictCategory, selectedDiseaseCategory, selectedLanguage, page, itemsPerPage, selectedEventSubType, selectedSourceCategory, selectedSortType, authenticationToken) }, 1000);
      } else {
        setShortlistEventIndexState(-1)
        setUnrejectEventSnackBarOpen(true)
        setSuccessfullUnrejectEvent(false)
      }
      setButtonFreeze(false);
    })
    setRenderCards(!renderCards)
  }

  function tutorialCompletedFunc() {
    dispatch(onboardingCompleted(userId, authenticationToken));
  }

  function reloadIfNetworkErrorOccur () {
    callEvents(fromDateFilter, toDateFilter, content, selectedStateCategory, selectedDistrictCategory, selectedDiseaseCategory, selectedLanguage, page, itemsPerPage, selectedEventSubType, selectedSourceCategory, selectedSortType, authenticationToken);
  }

  function hideEventFunc (clusterId) {
    dispatch(hideEvent(clusterId, authenticationToken)).then((res) => {
      if (res.success) {
        console.log("Event hide successfully");
        fetchEvents()
      } else {
        console.log("Event hide failed");
      }
    })
  }

  React.useEffect(() => {
    setMounted(true);
    return () => {
      setMounted(false);
    };
  }, []);

  React.useEffect(() => {
    if (mounted && isOnline) {
      reloadIfNetworkErrorOccur();
    }
  }, [isOnline])

  return (
    <div>
      {authenticatedWebsite &&
        <Box sx={{ display: 'flex', position: 'relative' }}>
          {!isOnboardingCompleted && <JoyRideSolutionPage fromDateFilter={fromDateFilter} toDateFilter={toDateFilter} selectedDiseaseCategory={selectedDiseaseCategory} diseaseCategories={diseaseCategories} selectedStateCategory={selectedStateCategory} stateCategories={stateCategories} selectedDistrictCategory={selectedDistrictCategory} districtCategories={districtCategories} selectedLanguage={selectedLanguage} languageList={languageList} sourceCategories={sourceCategories} selectedSourceCategory={selectedSourceCategory} aiTranslationFlag={aiTranslationFlag} renderCards={renderCards} selectedEventSubType={selectedEventSubType} sortTypeCategories={sortTypeCategories} selectedSortType={selectedSortType} showTutorial={!isOnboardingCompleted} tutorialCompleted={tutorialCompletedFunc} setShowTutorial={setOnboardingCompleted}/> }
          <CssBaseline />
          <Drawer variant="persistent" anchor="top" open={true} >
            <Divider />
            <div>
              <Grid container >
                <Grid item lg={3} md={3} sm={3} style={{ display: "flex", justifyContent: "center", alignItems: "center", cursor: "pointer", paddingTop: "25px", paddingBottom: "25px" }} onClick={openDashboard}>
                  <CustomHeaderButton heading={"Dashboard"} content={content} />
                </Grid>
                <Grid item lg={3} md={3} sm={3} style={{ display: "flex", justifyContent: "center", alignItems: "center", cursor: "pointer", paddingTop: "25px", paddingBottom: "25px" }} onClick={clickExtractedEvents}>
                  <CustomHeaderButton heading={"Media Alerts List"} content={content} />
                </Grid>
                <Grid id={onboardingSelectorIds.shortListTab} item lg={3} md={3} sm={3} style={{ display: "flex", justifyContent: "center", alignItems: "center", cursor: "pointer", paddingTop: "25px", paddingBottom: "25px" }} onClick={clickShortlistedEvents}>
                  <CustomHeaderButton heading={"Shortlisted"} content={content} />
                </Grid>
                <Grid id={onboardingSelectorIds.publishTab} item lg={3} md={3} sm={3} style={{ display: "flex", justifyContent: "center", alignItems: "center", cursor: "pointer", paddingTop: "25px", paddingBottom: "25px" }} onClick={clickPublished}>
                  <CustomHeaderButton heading={"Published"} content={content} />
                </Grid>
              </Grid>
            </div>
          </Drawer>
          {content === "dashboard" && <div style={{ paddingTop: "80px", width: "100%", }}>
            <Grid container >
              <Grid item lg={12} md={12} sm={12}>
                <SolutionDashboard dashboardPerformances={dashboardPerformances} selectedPerformance={selectedDashboardPerformance} guestToken={guestToken} superSetDomain={superSetDomain} superSetID={superSetID} setSelectedDashboardPerformance={setSelectedDashboardPerformance} refreshDashboard={getDashboardData} />
              </Grid>
            </Grid>
          </div>
          }
          {content !== "dashboard" && <div style={{ paddingTop: "80px", width: "100%" }}>
            <Grid container>
              <Grid item lg={12} md={12} sm={12} style={{ position: "sticky", top: "80px", zIndex: 999, backgroundImage: `url(` + bgImage + `)`, backgroundColor: "#D4EAF0", backgroundSize: "cover", boxShadow: "0px 5px 10px lightgrey" }}>
                <FilterSection fromDateFilter={fromDateFilter} toDateFilter={toDateFilter} selectedDiseaseCategory={selectedDiseaseCategory} diseaseCategories={diseaseCategories} selectedStateCategory={selectedStateCategory} stateCategories={stateCategories} selectedDistrictCategory={selectedDistrictCategory} districtCategories={districtCategories} selectedLanguage={selectedLanguage} languageList={languageList} sourceCategories={sourceCategories} selectedSourceCategory={selectedSourceCategory} content={content} countEvents={countEvents} noOfPages={noOfPages} page={page} aiTranslationFlag={aiTranslationFlag} renderCards={renderCards} collapseFlag={collapseFlag} eventSubTypes={eventSubTypes} selectedEventSubType={selectedEventSubType} sortTypeCategories={sortTypeCategories} selectedSortType={selectedSortType} totalEventsCount={totalEventsCount} showLoaderForTotalCount={showLoaderForTotalCount} stateUserFlag={stateUserFlag} handleFromDateChange={handleFromDateChange} handleToDateChange={handleToDateChange} handleDiseaseCategoryChange={handleDiseaseCategoryChange} handleStateCategoryChange={handleStateCategoryChange} handleDistrictCategoryChange={handleDistrictCategoryChange} handleLanguageChange={handleLanguageChange} handleSourceChange={handleSourceChange} fetchEvents={fetchEvents} openCustomPublishDialog={openCustomPublishDialog} handlePageChange={handlePageChange} setAiTranslationFlag={setAiTranslationFlag} setShowRejectedSourceList={setShowRejectionDialog} setCollapseFlag={setCollpaseFlag} setSelectedEventSubType={getEventsBySubType} downloadReport={downloadReportDialog} setSelectedSortType={setSelectedSortType} />
              </Grid>
              {/* {content === "shortlistedEvents" &&
                <Grid container style={{ padding: "1rem" }}>
                  <Grid item lg={12} md={12} sm={12} style={{ background: "#FFCFCF", padding: "10px 10px 10px 20px", border: "1px solid red", marginBottom: "1rem" }}>
                    <Typography>You cannot undo once the Alert is created. Please make sure you have recieved the required approvals for this alert</Typography>
                  </Grid>
                </Grid>} */}
              <Grid container key={renderCards} style={{ padding: "2rem" }}>
                <Grid item lg={12} md={12} sm={12}>
                  <Cards stateUserFlag={stateUserFlag} extractedEventsState={extractedEventsState} shortlistedEventsState={shortlistedEventsState} publishedEventsState={publishedEventsState} importantNoEventArticles={importantNoEventArticles} content={content} aiTranslationFlag={aiTranslationFlag} countEvents={countEvents} page={page} selectedEventSubType={selectedEventSubType} buttonFreeze={buttonFreeze} isValidEventData={isValidEventData} diseaseCategories={diseaseCategories} setExtractedEventsState={setExtractedEventsState} setShortlistedEventsState={setShortlistedEventsState} setPublishedEventsState={setPublishedEventsState} setImportantNoEventArticles={setImportantNoEventArticles} setPublishEventIndexState={setPublishEventIndexState} setShortlistEventIndexState={setShortlistEventIndexState} setRejectEventIndexState={setRejectEventIndexState} setRepublishDialogBoxState={setRepublishDialogBoxState} setSelectedEventData={setSelectedEventData} openRelatedEventsDialog={getClusterEvents} openSummaryDialog={openSummaryDialog} sendClusterForApproval={sendClusterForApproval} approveActionForCluster={approveActionForCluster} sendEmailForCluster={sendEmailForCluster} openPDFDialog={openPrintPDFDialog} unRejectCluster={unRejectCluster} setIsValidEventData={setIsValidEventData} updateEventAsChecked={eventRead} setShowRejectSourceDialog={setShowRejectionDialog} hideEventFunc={hideEventFunc} />
                </Grid>
              </Grid>
            </Grid>
            <Grid>
              {customPublishDialogFlag && <CustomPublishDialog dialogFlag={customPublishDialogFlag} customEventDate={customEventDate} customEventDisease={customEventDisease} finalDiseases={diseaseCategories} customEventNumberOfCases={customEventNumberOfCases} customEventState={customEventState} customStateDistrictList={customStateDistrictList} customEventDistrict={customEventDistrict} customEventURL={customEventURL} customEventLanguage={customEventLanguage} languageList={languages} customEventIncident={customEventIncident} incidentEnums={incidentEnums} customEventMessage={customEventMessage} setCustomEventDate={setCustomEventDate} setCustomEventDisease={setCustomEventDisease} setCustomEventNumberOfCases={setCustomEventNumberOfCases} setCustomEventState={setCustomEventState} setCustomEventDistrict={setCustomEventDistrict} capitalizeFirstLetter={capitalizeFirstLetter} setCustomEventURL={setCustomEventURL} setCustomEventLanguage={setCustomEventLanguage} setCustomEventIncident={setCustomEventIncident} setCustomEventMessage={setCustomEventMessage} closeDialog={setCustomPublishDialogFlag} customPublishEvent={customPublishEvent} />}
            </Grid>
            <Grid>
              {republishDialogFlag && <RepublishDialog dialogFlag={republishDialogFlag} stateDistrictList={customStateDistrictList} finalDiseases={diseaseCategories} eventData={{ ...selectedEventData }} closeDialog={setRepublishDialogBoxState} republishData={setRepublishEventData} />}
            </Grid>
            <Grid>
              {summaryDialogFlag && <SummaryDialog dialogFlag={summaryDialogFlag} token={authenticationToken} summaryData={{ ...summaryData }} isFetching={summaryDataFetchingFlag} closeDialog={setSummaryDialogFlag} openSummaryDialog={openSummaryDialog} />}
            </Grid>
            <Grid>
              {clusterEventDialogFlag && <ClusterDialog dialogFlag={clusterEventDialogFlag} stateUserFlag={stateUserFlag} token={authenticationToken} eventsList={clusterEventList} content={content} renderCards={renderCards} aiTranslationFlag={aiTranslationFlag} selectedEventSubType={selectedEventSubType} isValidEventData={isValidEventData} diseaseCategories={diseaseCategories} setExtractedEventsState={setExtractedEventsState} setShortlistedEventsState={setShortlistedEventsState} setPublishedEventsState={setPublishedEventsState} setImportantNoEventArticles={setImportantNoEventArticles} setRenderCards={setRenderCards} setPublishEventIndexState={setPublishEventIndexState} setShortlistEventIndexState={setShortlistEventIndexState} setRepublishDialogBoxState={setRepublishDialogBoxState} setRemoveEventIndexState={setRemoveEventIndexState} setSelectedEventData={setSelectedEventData} setSummaryDialogBoxState={setSummaryDialogFlag} setSelectedSummaryData={setSummaryData} closeDialog={setClusterEventDialogFlag} sendClusterForApproval={sendClusterForApproval} approveActionForCluster={approveActionForCluster} sendEmailForCluster={sendEmailForCluster} printPublishPdf={printPublishPdf} updateEventAsChecked={eventRead} unRejectCluster={unRejectCluster} setIsValidEventData={setIsValidEventData} />}
            </Grid>
            <Grid>
              {generatePDFDialogFlag && <GeneratePDFDialog eventId={selectedEventId} eventData={selectedEventForPrintPDF} dialogFlag={generatePDFDialogFlag} closeDialogFun={setGeneratePDFDialogFlag} generatePDF={printPublishPdf} setPdfDownloadedFlag={setPdfDownloadCompletedFlag} />}
            </Grid>
            <Grid>
              {downloadReportDialogFlag && <DownloadReportDialog dialogFlag={downloadReportDialogFlag} downloadReportFlag={downloadReportFlag} jobId={downloadReportJobId} timer={downloadReportTimer} timerCompleteFlag={downloadReportTimerFlag} closeDialog={setDownloadReportDialogFlag} createReport={downloadReportDialog} downloadReport={downloadReport}/>}
            </Grid>
            <Grid>
              {pdfDownloadCompletedFlag && <PDFCompletedDialog showDialog={pdfDownloadCompletedFlag} infoId={generatedPdfInfoId} setShowDialog={setPdfDownloadCompletedFlag} />}
            </Grid>
            <Grid>
              {(showRejectionDialog.showPostRejectionClick || showRejectionDialog.showRejectedSourceList) && <SourceRejection showDialog={showRejectionDialog} aiTranslationFlag={aiTranslationFlag} token={authenticationToken} setShowDialog={setShowRejectionDialog} fetchEvents={fetchEvents} />}
            </Grid>
            <CustomSnackBar openFlag={snackBarOpen} successFlag={successfullShortlist} successText={"Event Added to Shortlist"} failedText={"Event Not Added to Shortlist"} closeFun={handleSnakBarClose} />
            <CustomSnackBar openFlag={publishSnackBarOpen} successFlag={successfullPublish} successText={"Event Added to Publish"} failedText={"Event Not Added to Publish"} closeFun={handlePublishSnackBarClose} />
            <CustomSnackBar openFlag={customPublishSnackBarOpen} successFlag={successfullCustomPublish} successText={"Custom Event Added to Publish"} failedText={"Custom Event Not Added to Publish"} closeFun={handleCustomPublishSnackBarClose} />
            <CustomSnackBar openFlag={rejectSnackBarOpen} successFlag={successfullReject} successText={"Event Rejected Successfully"} failedText={"Unable To Reject Event"} closeFun={handleRejectSnackBarClose} />
            <CustomSnackBar openFlag={removeSnackBarOpen} successFlag={successfullRemove} successText={"Cluster Event Removed Successfully"} failedText={"Unable To Remove Cluster Event"} closeFun={handleRemoveSnackBarClose} />
            <CustomSnackBar openFlag={eventCountLimit} successFlag={false} failedText={"Number of events greater than 10000 in the given range"} closeFun={handleSnakBarCloseEventLimit} />
            <CustomSnackBar openFlag={sendApprovalSnackBarOpen} successFlag={successfullSendApproval} successText={"Event Sent For Approval"} failedText={"Event Not Sent For Approval"} closeFun={handleSendApprovalSnackBarClose} />
            <CustomSnackBar openFlag={unrejectEventSnackBarOpen} successFlag={successfullUnrejectEvent} successText={"Event Unreject Successfully"} failedText={"Event Cannot Be Unrejected"} closeFun={handleUnrejectEventSnackBarClose} />
            <CustomSnackBar openFlag={printPDFSnackBarOpen} successFlag={false} failedText={"Please Fill All The Details"} closeFun={handlePrintPDFSnackBarClose} />
            <CustomSnackBar openFlag={downloadReportFaliSnackbar} successFlag={false} failedText={"Please try again"} closeFun={handleDownloadReportSnackBarClose} />
            <CustomSnackBar openFlag={!isValidEventData} successFlag={false} failedText={"Please fill all the required fields"} closeFun={handleValidationSnakBarClose} />
            <CustomSnackBar openFlag={filterDateEmptySnackbar} successFlag={false} failedText={"Please fill the filter date fields"} closeFun={handleFilterDateEmptySnakBarClose} />

          </div>}
          <CustomHelpButton shiftPosition={collapseFlag}/>
          <NetworkErrorPage isOnline={isOnline} />
        </Box>
      }
    </div>
  );
}
