import * as React from 'react';
import { Box, Grid } from '@material-ui/core';
import CustomSubTabs from '../Shared/CustomSubTabs/CustomSubTabs';
import SolutionDashboardIframe from './SolutionDashboardIframe/SolutionDashboardIframe';
import CustomIconButton from '../Shared/CustomIconButton/CustomIconButton';
import refreshIconTwo from "../../resources/refreshIconTwo.png";
import "./SolutionDashboard.css";

const SolutionDashboard = ({ dashboardPerformances, selectedPerformance, guestToken, superSetDomain, superSetID, setSelectedDashboardPerformance = () => { }, refreshDashboard = () => { } }) => {
    return (
        <Grid container>
        <Grid item lg={12} md={12} sm={12} >
            <Grid container style={{ background: "#f7f7f7", padding: "2rem", position: "sticky", top: "80px", left: 0 }}>
                <Grid item lg={12} md={12} sm={12} style={{ display: "flex", alignItems: "center" }}>
                    {dashboardPerformances.length > 0 && <CustomSubTabs width="355px" items={dashboardPerformances} selectedItem={selectedPerformance} showCountFlag={false} saveSelection={setSelectedDashboardPerformance} />}
                    <div>
                        <CustomIconButton icon={refreshIconTwo} rounded={true} tooltipText={"Refresh Dashboard"} clicked={refreshDashboard} heightIcon={"1.5rem"} />
                    </div>
                </Grid>
            </Grid>
            <Grid container>
                <Grid item lg={12} md={12} sm={12} >
                    <Box m="0" height="85vh" width="100%">
                        <SolutionDashboardIframe guestToken={guestToken} superSetDomain={superSetDomain} superSetID={superSetID}/>
                    </Box>
                </Grid>
            </Grid>
        </Grid>
    </Grid>
    )
}

export default SolutionDashboard;
