import React from 'react';
import './RejectedSourcListsWrapper.css';
import CustomDialogBox from '../Shared/CustomDialogBox/CustomDialogBox';
import newsPaperIcon from '../../resources/newspaperIcon.png'
import sortIcon from '../../resources/sortIcon.png'
import returnIcon from '../../resources/returnIcon2.png'
import dorpdownIcon from '../../resources/arrowIcon.svg'
import { Grid } from '@material-ui/core';
import RejectedEventCard from './RejectedEventCard';
import { getBlockedEvent } from '../../actions/global';
import moment from 'moment';
import { useDispatch } from 'react-redux';

const RejectedSource = ({ sourceData, aiTranslationFlag, token, handleUnblockSource = () => { } }) => {
    const dispatch = useDispatch()
    const [showCard, setShowCard] = React.useState(false)
    const [cardData, setCardData] = React.useState(null)
    const handleSowCardButtonClick = () => {
        const jsonObj = {
            eventID: sourceData.event_id
        }
        if (!cardData && !showCard) {
            dispatch(getBlockedEvent(jsonObj, token)).then((res) => {
                if (res.success) {
                    setShowCard(!showCard)
                    setCardData(res?.data?.event)
                }
            })
        } else {
            setShowCard(!showCard)
        }
        
    }

    const handleUnblock = () => {
        const tempObj = {
            event_id: sourceData.event_id,
            source_name: sourceData.source_name
        }
        handleUnblockSource(tempObj)
    }

    return <>
        <Grid container style={{ width: "100%", maxWidth: "100%", padding: "1rem 1rem 1rem 2rem", background: showCard ? "#D4EAF0" : "transparent", boxSizing: "border-box", borderBottom: "2px solid #D3E2EC" }}>
            <Grid item lg={12} md={12} sm={12} >
                <Grid container >
                    <Grid item lg={12} md={12} sm={12} style={{ width: "100%", display: "flex", alignItems: "center", color: "#000", marginBottom: showCard ? "1rem" : 0, fontSize: "x-large", fontWeight: "700" }}>
                        <div style={{ width: "100%", display: "flex", columnGap: "15px", justifyContent: "space-between", alignItems: "center" }}>
                            <div style={{ fontSize: "calc(0.8vw + 8px)", color: "#413F40", fontWeight: "500" }}>{sourceData.source_name}</div>
                            <div style={{ display: "flex", columnGap: "15px" }}>
                                <div style={{ display: "flex", columnGap: "7px", borderRadius: "20px", background: '#16754C', alignItems: "center", cursor: "pointer", padding: "6px 25px" }} onClick={handleUnblock} >
                                    <img src={returnIcon} alt="return-icon" style={{ height: "24px", width: "24px" }} />
                                    <div style={{ fontSize: "14px", fontWeight: "400", color: "#fffffd" }}>Unblock Source</div>
                                </div>
                                <div style={{ display: "flex", columnGap: "7px", borderRadius: "20px", background: "#D4EAF0", alignItems: "center", justifyContent: "center", cursor: "pointer", padding: "6px 0px", width: "10rem" }} onClick={handleSowCardButtonClick} >
                                    <div style={{ fontSize: "14px", fontWeight: "400", color: "#008BCA" }}>{showCard ? "Close event" : "View event"}</div>
                                    <img src={dorpdownIcon} alt="arrow-icon" style={{ height: "16px", transform: showCard ? "rotate(180deg)" : "rotate(0deg)" }} />
                                </div>
                            </div>
                        </div>
                    </Grid>
                    <Grid item lg={12} md={12} sm={12} >
                        {showCard && cardData?.number && <RejectedEventCard eventData={cardData} aiTranslationFlag={aiTranslationFlag} sourceName={sourceData.source_name} />}
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    </>
}

const GroupRejectedSouce = ({ sourceData, aiTranslationFlag, token, handleUnblockSource = () => { } }) => {
    return <>
        {sourceData?.map(data => <Grid key={data.date} container style={{ background: "#fff", maxWidth: "100%", background: "#F3FAFF", borderRadius: "3.5px", boxShadow: "#C7C3C3 2px 2px 6px", marginBottom: "15px" }}>
            <Grid item lg={12} md={12} sm={12} >
                <div style={{ fontSize: "16px", color: "#78828C", padding: "1rem 2rem", fontWeight: "600" }}>
                    {moment(data.date).format("DD/M/YYYY")}
                </div>
                {data.items.map(eventData => <RejectedSource key={eventData.source_name} sourceData={eventData} aiTranslationFlag={aiTranslationFlag} token={token} handleUnblockSource={handleUnblockSource} />)}
            </Grid>
        </Grid>)}
    </>
}

const sortByDate = (arr, sortOrder) => {
    const sortedArray = arr.sort((a, b) => {
        const dateA = a.date;
        const dateB = b.date;
        if (sortOrder === 'asc') {
            return new Date(dateA) - new Date(dateB);
        } else if (sortOrder === 'desc') {
            return new Date(dateB) - new Date(dateA);
        }
    });
    return sortedArray
};

const RejectedSourcListsWrapper = ({ showDialog, blockedSourceData, aiTranslationFlag, token, setShowDialog = () => { }, handleUnblockSource = () => {} }) => {
    const sortTypeConstants = ["Recent to oldest", "Old to new"]
    const [sortType, setSortType] = React.useState(sortTypeConstants[0])
    const [sortedSourceData, setSortedSourceData] = React.useState([])
    const handleShortTypeClick = () => {
        setSortType((prev) => (
            prev === sortTypeConstants[0] ? sortTypeConstants[1] : sortTypeConstants[0]
        ))
    }

    React.useEffect(() => {
        if (sortType === sortTypeConstants[0]) {
            const data = sortByDate(blockedSourceData.data, "desc")
            setSortedSourceData([].concat(data))
        } else {
            const data = sortByDate(blockedSourceData.data, "asc")
            setSortedSourceData([].concat(data))
        }
    }, [blockedSourceData.data, sortType])

    const component = <>
        <Grid container style={{ background: "#fff", width: "70vw", maxWidth: "90rem", maxHeight: "90vh", padding: "2rem", borderRadius: "12px", boxSizing: "border-box" }}>
            <Grid item lg={12} md={12} sm={12} style={{ padding: "2rem 2rem 0" }}>
                <Grid container>
                    <Grid item lg={12} md={12} sm={12} style={{ display: "flex", alignItems: "center", color: "#000", fontSize: "x-large", fontWeight: "700" }}>
                        <div style={{ display: "flex", columnGap: "15px" }}>
                            <img style={{ height: "2rem" }} src={newsPaperIcon} alt="header-icon" />
                            <div style={{ fontSize: "28px", color: "#000", fontWeight: "600" }}>List of Blocked Sources</div>
                        </div>
                    </Grid>
                    <Grid item lg={12} md={12} sm={12} style={{ display: "flex", alignItems: "center", justifyContent: "flex-end", color: "#000", fontSize: "x-large", fontWeight: "700", borderBottom: "1px solid #78828C", paddingBottom: "0.5rem" }}>
                        <div style={{ display: "flex", columnGap: "10px", cursor: 'pointer', alignItems: 'center' }} onClick={handleShortTypeClick}>
                            <img style={{ height: "1rem", transform: sortType === sortTypeConstants[1] ? "rotate(180deg)" : "rotate(0deg)" }} src={sortIcon} alt="sort-icon" />
                            <div style={{ color: "78828C", fontSize: "15px", fontWeight: "500" }}>{sortType}</div>
                        </div>
                    </Grid>
                </Grid>
            </Grid>
            <Grid item lg={12} md={12} sm={12} style={{ paddingRight: "1.3rem", margin: "1rem 0rem" }}>
                <div style={{ overflowY: "auto", padding: "0 1.5rem 2rem 2rem", maxHeight: "calc(100vh - 10vh - 20rem)" }} >
                    <GroupRejectedSouce sourceData={sortedSourceData} aiTranslationFlag={aiTranslationFlag} token={token} handleUnblockSource={handleUnblockSource} />
                </div>
            </Grid>
        </Grid>
    </>

    return <>
        <CustomDialogBox showDialog={showDialog} InnerComp={component} setShowDialog={setShowDialog} />
    </>
}

export default RejectedSourcListsWrapper;
