import * as React from 'react';
import "./SolutionDashboardIframe.css";

const SolutionDashboardIframe = ({ guestToken, superSetDomain, superSetID }) => {
  React.useEffect(() => {
    window.supersetEmbeddedSdk.embedDashboard({
      id: superSetID,
      supersetDomain: superSetDomain,
      mountPoint: document.getElementById('container'),
      fetchGuestToken: () =>
        guestToken,
      dashboardUiConfig: { hideTitle: true }
      })
  }, [guestToken, superSetDomain, superSetID]);
  return (
    <div id="container" style={{ width: '100vw', height: '100%' }}>
    </div>
  )
}

export default SolutionDashboardIframe;
